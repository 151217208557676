import { Box, Checkbox, SxProps, Theme } from '@mui/material';
import { StyledBodyText, StyledHeaderTextH2 } from '../../../../../common/Styleguide/Common/Text';
import React, { useContext } from 'react';
import { SubmissionContext } from './Context/SubmissionProvider';
import { V3Conditions } from '../../Admin/Game/RevenueShare/V3Conditions';
import { V2Conditions } from '../../Admin/Game/RevenueShare/V2Conditions';
import { V1Conditions } from '../../Admin/Game/RevenueShare/V1Conditions';
import { StyledFormControlLabel } from './RevenueShareForm/revenueShareForm.styles';
import PEGIRatingLink from '../../../../../common/components/PEGIRatingLink';
import { DOCS_URL } from '../../../SideMenu/Menu';
import TermsLink from '../../../../../common/components/TermsLink';
import { getLatestBuild } from '../../../../../common/build-utils';

const CommercialDetailsTab: React.FC = () => {
  const { submission } = useContext(SubmissionContext);

  if (!submission || !submission.rawRevenueShareOptions) {
    return <>Missing revenue share data</>;
  }

  const isIframe = getLatestBuild(submission.gameBuilds).loaderType === 'iframe';

  const renderCheckbox = (value: boolean, label: string | React.JSX.Element, sx?: SxProps<Theme> | undefined) => {
    return <StyledFormControlLabel sx={{ m: 0, ...sx }} control={<Checkbox checked={value} disabled />} label={label} />;
  };

  const renderIframeRevenueShareDisabled = () => {
    return (
      <StyledBodyText color="white30">
        Revenue share is not available for games embedded via iframe. Read more about revenue share conditions in section 5.3 in our{' '}
        <TermsLink />.
      </StyledBodyText>
    );
  };

  const renderV1 = () => {
    const conditions: V1Conditions = JSON.parse(submission!.rawRevenueShareOptions!);

    return (
      <>
        {renderCheckbox(conditions.noThirdPartyBranding, 'No third-party branding is present in the game.')}
        {renderCheckbox(conditions.exclusiveOnCrazygames, 'The game is exclusively available on CrazyGames.')}
        {renderCheckbox(conditions.notPublishedElsewhere, 'The game is not published elsewhere.')}
        {renderCheckbox(conditions.crazygamesLogoEmbedded, 'The CrazyGames logo is embedded in the game.')}
        {renderCheckbox(conditions.crazygamesRedistributionAllowed, 'Redistribution of the game is allowed by CrazyGames.')}
      </>
    );
  };

  /** v2 is similar to v3 but is also asks if the developer has implemented a logo */
  const renderV2 = () => {
    const conditions: V2Conditions = JSON.parse(submission!.rawRevenueShareOptions!);
    const isInvited = conditions.isInvited || submission.isInvited;
    return (
      <>
        {!isInvited && (
          <>
            {renderCheckbox(true, 'I submit this game on my own initiative, without an invitation by or agreement with CrazyGames.')}
            {renderCheckbox(
              true,
              <>
                I agree to publish the games under the <TermsLink /> of the developer portal.
              </>,
              { pl: 5 },
            )}
          </>
        )}
        {isInvited && (
          <>
            {renderCheckbox(
              true,
              'I have been invited by CrazyGames to submit the game and/or my game has an agreement contract with CrazyGames. Selecting this option without an invitation will result in automatic rejection of your submission.',
            )}
            {!conditions.hasCustomContract &&
              renderCheckbox(
                true,
                <>
                  I publish the games under the <TermsLink /> of the developer portal.
                </>,
                { pl: 5 },
              )}
            {conditions.hasCustomContract &&
              renderCheckbox(
                true,
                <>
                  I have signed a custom contract with CrazyGames. If this option is mistakenly selected, I accept the general <TermsLink />
                  of the developer portal.
                </>,
                { pl: 5 },
              )}
          </>
        )}
        {renderCheckbox(
          conditions.suitableForChildren,
          <>
            I confirm that my game is suitable for players aged 12+. In particular, I certify that it suits the <PEGIRatingLink /> criteria.
          </>,
        )}
        {isIframe && renderIframeRevenueShareDisabled()}
        {!isIframe && (
          <>
            {renderCheckbox(
              conditions.isEligible,
              'I read and understood article 5.3 and acknowledge that my game is eligible for revenue share.',
            )}
            {renderCheckbox(
              conditions.makeExclusive,
              'I will make this game exclusively available on CrazyGames for two months following the release date. I understand that after these two months I am free to upload my game anywhere I like.',
            )}
            {renderCheckbox(
              conditions.hasImplementedSDK,
              <>
                I implemented the CrazyGames SDK, including the in-game advertisements (
                <a href={`${DOCS_URL}/`} target="_blank" rel="noopener noreferrer">
                  SDK Documentation
                </a>
                ).
              </>,
            )}
            {renderCheckbox(conditions.hasImplementedLogo, 'I included a CrazyGames logo in game')}
            {renderCheckbox(conditions.disallowEmbedding, "I don't want an embed button displayed on the play page of my game.")}
          </>
        )}
      </>
    );
  };

  const renderV3 = () => {
    const conditions: V3Conditions = JSON.parse(submission!.rawRevenueShareOptions!);
    const isInvited = submission.isInvited;
    return (
      <>
        {!isInvited && (
          <>
            {renderCheckbox(true, 'I submit this game on my own initiative, without an invitation by or agreement with CrazyGames.')}
            {renderCheckbox(
              true,
              <>
                I agree to publish the games under the <TermsLink /> of the developer portal.
              </>,
              { pl: 5 },
            )}
          </>
        )}
        {isInvited && (
          <>
            {renderCheckbox(
              true,
              'I have been invited by CrazyGames to submit the game and/or my game has an agreement contract with CrazyGames. Selecting this option without an invitation will result in automatic rejection of your submission.',
            )}
            {!conditions.hasCustomContract &&
              renderCheckbox(
                true,
                <>
                  I publish the games under the <TermsLink /> of the developer portal.
                </>,
                { pl: 5 },
              )}
            {conditions.hasCustomContract &&
              renderCheckbox(
                true,
                <>
                  I have signed a custom contract with CrazyGames. If this option is mistakenly selected, I accept the general <TermsLink />
                  of the developer portal.
                </>,
                { pl: 5 },
              )}
          </>
        )}
        {renderCheckbox(
          conditions.suitableForChildren,
          <>
            I confirm that my game is suitable for players aged 12+. In particular, I certify that it suits the <PEGIRatingLink /> criteria.
          </>,
        )}
        {renderCheckbox(
          conditions.isEligible,
          'I read and understood article 5.3 and acknowledge that my game is eligible for revenue share.',
        )}
        {/* At some point we changed the revenue share form and allowed devs also to tick the above checkbox. Stuff below is still shown however only for non iframe games, also on revenue share form. */}
        {!isIframe && (
          <>
            {renderCheckbox(
              conditions.makeExclusive,
              'I will make this game exclusively available on CrazyGames for two months following the release date. I understand that after these two months I am free to upload my game anywhere I like.',
            )}
            {renderCheckbox(
              conditions.hasImplementedSDK,
              <>
                I implemented the CrazyGames SDK, including the in-game advertisements (
                <a href={`${DOCS_URL}/`} target="_blank" rel="noopener noreferrer">
                  SDK Documentation
                </a>
                ).
              </>,
            )}
            {renderCheckbox(conditions.disallowEmbedding, "I don't want an embed button displayed on the play page of my game.")}
          </>
        )}
      </>
    );
  };

  const renderRevenueShareVersion: () => JSX.Element | null = () => {
    if (submission.revenueShareVersion === undefined) {
      return null;
    }
    switch (submission.revenueShareVersion) {
      case 1:
        return renderV1();
      case 2:
        return renderV2();
      case 3:
        return renderV3();
    }
  };

  return (
    <Box sx={{ ml: 2.5, pt: 2 }}>
      <StyledHeaderTextH2 sx={{ mt: 1, mb: 0 }}>Commercial details</StyledHeaderTextH2>
      <div style={{ display: 'flex', flexDirection: 'column' }}>{renderRevenueShareVersion()}</div>
    </Box>
  );
};

export default CommercialDetailsTab;
